import PropTypes from "prop-types";
import React from "react";
import Modal from "react-modal";

import Close from "@/assets/icons/overlay-close-white.svg";

import styles from "./index.module.scss";

Modal.setAppElement("#__next");

// Margin Top Param accepts a integer 0-50 ///// 50 being centered
const ModalWrap = ({
  children,
  label,
  open,
  fullScreen = false,
  displayClose = false,
  marginTop = 15,
  onRequestClose = () => {},
}) => {
  const overlayTypes = {
    centered: {
      content: {
        backgroundColor: "transparent",
        border: "none",
        bottom: "auto",
        left: "50%",
        marginBottom: "50px",
        marginRight: "-50%",
        overflow: "visible",
        padding: "0px",
        right: "auto",
        top: `${marginTop}%`,
        transform: `translate(-50%, 0%)`,
      },
      overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.95)",
        height: "100vh",
        overflowX: "scroll",
        zIndex: 9999,
      },
    },
    fullScreen: {
      content: {
        backgroundColor: "transparent",
        border: "none",
        bottom: "0px",
        left: "0px",
        padding: "0px",
        right: "0px",
        top: "0px",
      },
      overlay: {
        backgroundColor: "transparent",
        height: "100vh",
        overflow: "visible",
        width: "100vw",
        zIndex: 9999,
      },
    },
  };
  const customStyles = {
    content: fullScreen
      ? overlayTypes.fullScreen.content
      : overlayTypes.centered.content,
    overlay: fullScreen
      ? overlayTypes.fullScreen.overlay
      : overlayTypes.centered.overlay,
  };

  return (
    <>
      <Modal
        contentLabel={label}
        isOpen={open}
        onRequestClose={onRequestClose}
        style={customStyles}
      >
        {displayClose && (
          <button
            className={styles.closeButton}
            name="ModalWrap - Close"
            onClick={onRequestClose}
          >
            <Close />
          </button>
        )}
        {children}
      </Modal>
    </>
  );
};

export default ModalWrap;

ModalWrap.propTypes = {
  children: PropTypes.object,
  displayClose: PropTypes.bool,
  fullScreen: PropTypes.bool,
  label: PropTypes.string,
  marginTop: PropTypes.number,
  onRequestClose: PropTypes.func,
  open: PropTypes.bool,
};
