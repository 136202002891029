import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
// import Close from "@/assets/icons/close-white.svg";
import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";
import ReactContentfulImage from "react-contentful-image";

import ImageSizes from "@/utils/helpers/images";

import styles from "./index.module.scss";

const ContentModal = ({ data }) => {
  const {
    fields: { title, content, topImage, ingredients, directions },
  } = data;
  const topImageSrc = get(topImage, "fields.file.url", null);
  const topImageAltText = get(topImage, "fields.title", null);
  return (
    <>
      <div className={styles.contentModal}>
        <div className={`container container-fluid`}>
          <div className={`row ${styles.contentModalHeader}`}>
            <div className={`col-lg-5 ${styles.contentModalTitleCol}`}>
              <h2>{title}</h2>
            </div>
            <div
              className={`col-lg-9 offset-lg-2 ${styles.contentModalImageCol}`}
            >
              {topImageSrc && (
                <ReactContentfulImage
                  alt={topImageAltText}
                  sizes={ImageSizes.editorialContentHero}
                  src={topImageSrc}
                  title={topImageAltText}
                />
              )}
            </div>
          </div>
          <div className={`row`}>
            <div className={`col-lg-16`}>
              <div
                className={styles.contentModalContent}
                dangerouslySetInnerHTML={{
                  __html: documentToHtmlString(content),
                }}
              />
            </div>
          </div>
          <div className={`row`}>
            <div className={`col-lg-5 ${styles.contentModalIngredientsCol}`}>
              {ingredients && (
                <div>
                  <h3>Ingredients:</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: documentToHtmlString(ingredients),
                    }}
                  />
                </div>
              )}
            </div>
            <div
              className={`col-lg-9 offset-lg-2 ${styles.contentModalDirectionsCol}`}
            >
              {directions && (
                <div>
                  <h3>Directions:</h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: documentToHtmlString(directions),
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ContentModal.propTypes = {
  data: PropTypes.object,
};

export default ContentModal;
