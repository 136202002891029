import Utilities from "@/utils/helpers/formatting";

module.exports = {
  CarouselWithDescCard: [
    {
      mediaQuery: "default",
      params: { h: 900, w: 900 },
    },
  ],
  appContentDeviceDisplay: [
    {
      mediaQuery: "default",
      params: { w: 380 },
    },
  ],
  brandHeroMobile: [
    {
      mediaQuery: "default",
      params: { h: 423 },
    },
  ],
  brandHomePageImage: [
    {
      params: { h: 228, q: 75, w: 305 },
    },
  ],
  breakerDesktop: [
    {
      mediaQuery: "default",
      params: { w: 1800 },
    },
  ],
  breakerMobile: [
    {
      mediaQuery: "default",
      params: { w: 768 },
    },
  ],
  callout: [
    {
      mediaQuery: "default",
      params: { fit: "fill", w: 900 },
    },
  ],
  card: [
    {
      mediaQuery: "default",
      params: { w: 900 },
    },
  ],
  carouselWithVideoAndBackground_DesktopBackground: [
    {
      mediaQuery: "default",
      params: { fit: "fill", q: 70, w: 1950 },
    },
  ],
  carouselWithVideoAndBackground_MobileBackground: [
    {
      mediaQuery: "default",
      params: { fit: "fill", q: 70, w: 700 },
    },
  ],
  carouselWithVideoAndBackgroundDesktop: [
    {
      params: { h: 340, w: 660 },
      q: 75,
    },
  ],
  carouselWithVideoAndBackgroundMobile: [
    {
      mediaQuery: "default",
      params: { w: 306 },
      q: 75,
    },
  ],
  carouselWithVideoAndBackgroundTablet: [
    {
      f: "top_right",
      fit: "crop",
      params: { h: 340, w: 550 },
      q: 75,
    },
  ],
  classIcon: [
    {
      mediaQuery: "default",
      params: { fit: "thumb", h: 160, r: 160, w: 160 },
    },
  ],
  editorialBackground: [
    {
      mediaQuery: "default",
      params: { fit: "fill", h: 1160, q: 70, w: 900 },
    },
  ],
  editorialBackgroundMobile: [
    {
      mediaQuery: "default",
      params: { fit: "fill", h: 700, q: 70, w: 700 },
    },
  ],
  editorialBackgroundPortrait: () => {
    const settings = {
      fl: "progressive",
      fm: "jpg",
      q: 75,
      w: 900,
    };
    return Utilities.objectToQueryString(settings);
  },
  editorialContentHero: [
    {
      mediaQuery: "default",
      params: { fit: "fill", h: 570, q: 70, w: 1050 },
    },
  ],
  fullScreenBackgroundDesktop: () => {
    const settings = {
      fl: "progressive",
      fm: "jpg",
      q: 55,
      w: 2200,
    };
    return Utilities.objectToQueryString(settings);
  },
  fullScreenBackgroundMobile: () => {
    const settings = {
      fl: "progressive",
      fm: "jpg",
      q: 45,
      w: 768,
    };
    return Utilities.objectToQueryString(settings);
  },
  galleryDesktop: [
    {
      mediaQuery: "default",
      params: { w: 1800 },
    },
  ],
  galleryMobile: [
    {
      mediaQuery: "default",
      params: { w: 768 },
    },
  ],
  groupFitnessInstanceImage: [
    {
      params: { fit: "fill", h: 800, q: 70, w: 600 },
    },
  ],
  heroBackgroundImage: [
    {
      mediaQuery: "default",
      params: { w: 1800 },
    },
  ],
  heroBackgroundImageMobile: [
    {
      mediaQuery: "default",
      params: { w: 800 },
    },
  ],
  heroSplitScreen: [
    {
      mediaQuery: "default",
      params: { w: 1800 },
      q: 45,
    },
  ],
  homeHeroDesktop: [
    {
      mediaQuery: "default",
      params: { w: 1800 },
      q: 45,
    },
  ],
  homeHeroMobile: [
    {
      mediaQuery: "default",
      params: { w: 768 },
    },
  ],
  locationCard: [
    {
      mediaQuery: "default",
      params: { w: 440 },
    },
  ],
  navigationBrandImages: [
    {
      mediaQuery: "default",
      params: { fit: "thumb", h: 135, q: 60, w: 700 },
    },
  ],
  singleCard: [
    {
      mediaQuery: "default",
      params: { fit: "fill", h: 470, q: 70, w: 900 },
    },
  ],
  singleImageCarouselMobile: [
    {
      f: "top_right",
      fit: "crop",
      params: { h: 360, w: 640 },
      q: 45,
    },
  ],
  singleImageDesktop: [
    {
      mediaQuery: "default",
      params: { fit: "fill", q: 70, w: 1900 },
    },
  ],
  singleImageMobile: [
    {
      mediaQuery: "default",
      params: { fit: "fill", q: 70, w: 800 },
    },
  ],
  socialIcon: [
    {
      mediaQuery: "default",
      params: { h: 40, w: 40 },
    },
  ],
  sportsClubCommunity: [
    {
      mediaQuery: "default",
      params: { w: 850 },
      q: 45,
    },
  ],
};
